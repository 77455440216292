import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Grid } from '@mui/material';
import Icon from '../assets/_logos/icon.png';
import IconRuthsRoses from '../assets/_logos/ruthsroses-logo.svg';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const { hostname } = window.location;
  let icon = '';
  if (hostname === 'www.floranet.app') {
    icon = Icon;
  } else {
    icon = IconRuthsRoses;
  }
  const logo = (
    <Grid container>
      <Box sx={{ width: 100, height: 100, ...sx }}>
        <img src={icon} alt="" style={{ width: '100%', height: '100%' }} />
      </Box>
    </Grid>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
